.reservation-form-wrapper {
    position: absolute;
    right: 0;
    display: none;
    white-space: nowrap;
    height: auto;
    min-width: 100%;
    width: 270px;
    max-width: 270px;
    z-index: 3000;
    position: absolute;
    background: color('white');
    color: color('secondary');
    right: 0;
    padding: 20px;
    margin-top: -1px;
    // margin-right:1px;
    // border-radius: 10px;
    .form-group{
        display: flex;
        flex-wrap: nowrap;
    }
    label{
        display: inline-block;
        background: #e5e5e5;
        text-align: left;
        flex: 0 0 140px;
        max-width: 140px;
        font-size: 15px;
        line-height: 40px;
        padding: 0 15px;
    }
    .form-control{
        display: inline-block;
        border: 1px solid #e5e5e5;
        
        flex: 0 0 90px;
        max-width: 90px;
        @include border-radius(0);
        height: 40px;
        font-size: 15px;


    }
    select{
        box-sizing: border-box;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: color('white');
        background-image: url('../../../images/icons/select-icon.png');
        background-repeat: no-repeat;
        background-position: right 7px top 50%;
        background-size: 12px 6px;

    }
    
    button[type="submit"]{
        display: block;
        width: 100%;
        text-transform: uppercase;
        &:hover{
            color: color('primary') !important;
            text-transform: uppercase;
        }
    }
    .change-reservation{
        font-size: 14px;
        text-align: center;
        a{
            font-size: 14px;
            line-height: 42px;
            color: color('secondary');
            &:hover{
                text-decoration: none;
                color: color('primary');
            }
        }
    }
    
}

#ui-datepicker-div{
    transform: translateX(-36px);
}