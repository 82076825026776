/*
    Slider se koristi za Template V3  unutar Slider Config-a u CMS-u
    Služi kao grid prikaz
*/
.section {
    &.slider_v3 {
        padding: 0;

        .slider-title {
            margin-top: 80px;
        }

        .slider-text {
            margin-bottom: 35px;
        }

        .slider_wrapper {
            .slider_item {
                // margin-bottom: 25px;
                padding: 0;
                position: relative;
                .slider-link{
                    &:hover{
                        text-decoration: none;;
                    }
                }
                &.slider-item-left {
                    .slider-img {
                        float: left;
                        // margin-right: 20px;
                    }
                }

                &.slider-item-right {
                    .slider-img {
                        float: right;
                        margin-left: 20px;
                    }
                }

                .slider-img {
                    max-width: 100%;
                    height: auto;
                }

                .slider-img-overlay {
                    position: absolute;
                    background-color: rgba(0, 0, 0, .5);
                    height: 100%;
                    width: 100%;
                    @include transition(all .2s linear);

                    &:hover {
                        background-color: rgba(0, 0, 0, .3);
                    }
                }

                .item-content {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    @include translate(-50%, -50%);
                    text-align: center;
                    .item-title {
                        text-align: center;
                        font-size: 32px;
                        color: color('text', 'accent');
                        line-height: 1;
                        text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
                        @media(min-width: $media_sm){
                            font-size: 44px;
                        }
                    }
                    .item-text{
                        font-size: 18px;
                        margin-top: 25px;
                    }
                    // .slider-item-link {
                    //     color: color('primary');

                    //     &:hover {
                    //         color: color('text', 'dark');
                    //     }
                    // }
                }
            }
        }
    }
}