@import './background_image/background_image.scss';
@import './buttons/buttons.scss';
@import './scrollbar/scrollbar.scss';



/* Utitlity classes */

.red {
  color: color('error') !important;
}

.error {
  border-color: color('error') !important;
}

.text-muted {
  font-size: 1.2rem !important;
  color: color('footer', 'text', 'accent') !important;
  @include opacity(0.35);
}