.contact-section {
  .contact-text {
    margin-bottom: 35px;
  }

  // .contact-btn {
  //   background: color('primary');
  //   border: 2px solid color('primary');
  //   text-decoration: none;
  //   text-transform: uppercase;
  //   font-family: font(main);
  //   font-size: 1.125rem;
  //   font-weight: 600;
  //   color: color('white');
  //   padding: 10px 20px;
  //   border: 2px solid color('primary');
  //   @include transition(all .3s linear);

  //   &:hover {
  //     color: color('text', 'darker');
  //     background: transparent;
  //   }
  // }
}