/*
    Ovo je bazično stiliziranje za Slidere na stranici (osim HeaderSlider koji je odvojen)
    Svaki slider posebno je odvojen i ima svoje overrideove
*/

.section {
    &.slider-section {
        .container-fluid {
            .navi {
                &.navi-prev {
                    left: 1px;
                    color: color('white');
                }

                &.navi-next {
                    right: 1px;
                    color: color('white');
                }
            }

        }

        .container {
            .navi {
                &.navi-prev {
                    left: -30px;
                    color: color('white');
                }

                &.navi-next {
                    right: -30px;
                    color: color('white');
                }
            }

        }

        .slider_wrapper {

            .slider_item {
                .item-content {
                    .item-title {
                        font-family: font(main);
                        font-size: 16px;
                        font-weight: 300;
                        text-transform: uppercase;
                        line-height: 24px;
                        color: color('text', 'accent');
                        margin-bottom: 10px;
                        display: block;
                    }

                    .item-desc {
                        margin-bottom: 10px;
                        display: block;

                        p {
                            font-size: 14px;
                            font-family: font(sub);
                            font-weight: 400;
                            line-height: 24px;
                            color: color('text', 'light');
                        }
                    }

                    .slider-item-link {

                        color: color('primary');
                        font-size: 14px;
                        font-weight: 700;
                        font-family: font(sub);

                        i {
                            font-size: 16px;
                            color: color('primary');
                            margin-right: 5px;
                        }

                        &:hover {
                            text-decoration: none;

                            i {
                                -webkit-animation: leftToRight 1s ease-out infinite;
                                animation: leftToRight 1s ease-out infinite;
                            }
                        }

                    }
                }
            }

            .navi {
                position: absolute;
                top: 50%;
                margin-top: -25px;
                z-index: 1;
                cursor: pointer;
                font-size: 30px;
                background: color('primary');
                line-height: 30px;
                width: 30px;
                height: 30px;
                border-radius: $border_radius;
                -webkit-border-radius: $border_radius;
                -moz-border-radius: $border_radius;
                -ms-border-radius: $border_radius;
                -o-border-radius: $border_radius;



                &.slick-disabled {
                    @include opacity(0.2);
                    pointer-events: none;
                    cursor: default;
                }

                i {
                    font-size: 2.142857142857143rem;
                    font-weight: 600;
                }
            }

            .navi {
                color: #ffffff;
            }

            .slick-dots {
                position: absolute;
                bottom: -70px;
            }
        }

    }
}