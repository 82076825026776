.documents-wrapper {
    padding: 35px 0;
    .documents-title{
        text-align: left;
    }
    .documents {
        background: lighten(color('background', 'main'), 5%);
        padding: 20px;
        border: 1px solid color('border', 'main');
    }

    .document-icon {
        max-width: 14px;
        max-height: 14px;
        margin-right: 5px;
    }
    .documents-text{
        margin-bottom: 35px;
    }
    .document-button {
        display: block;
        color: color('text', 'placeholder');
        font-weight: 300;
        font-size: 14px;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        .document-name {
            // border-bottom: 1px solid color('border', 'underline');
            line-height: 1;
            display: inline-block;
            color: color('primary');
            font-weight: 600;
            font-size: 16px;
        }

        i {
            margin-right: 5px;
            color: color('primary');
            font-size: 16px;
        }

        &:hover {
            text-decoration: none;
            ;
        }
    }
}