.group-view-section {
    .list_component_wrapper {
        padding: 40px 0;

        .list-item {
            background-color: color('background', 'main');

            .list-link {
                &:hover {
                    text-decoration: none;
                }
            }

            &:not(:last-of-type) {
                margin-bottom: 40px;
            }

            // @include box-shadow(1px 1px 50px 2px rgba(0, 0, 0, 0.3));

            .list-item-img-wrapper {
                background-color: color('background', 'main');
                position: relative;
                .list-item-img-overlay{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0,0,0, .5);
                    opacity: 1;
                    @include transition(opacity 0.2s linear);

                    .list-item-img-text{
                        position: absolute;
                        bottom: 0;
                        padding: 30px;

                        .list-item-img-text-title{
                            font-size: 36px;
                            font-weight: 500;
                            text-transform: uppercase;   
                            line-height: 1;
                            color: color('text', 'accent')
                        }

                        .section-separator--left{
                            margin-top: 15px;
                            margin-bottom: 15px;
                            background: rgba(255,255,255, 0.4);
                            color: rgba(255, 255, 255, 0.4);

                            &:after{
                                background: rgba(255,255,255, 0.4);
                                color: rgba(255, 255, 255, 0.4);
                            }
                        }
                        .list-item-img-text-subtitle{
                            font-size: 18px;
                            font-weight: 500;
                            color: color('text', 'accent')

                        }
                    }
                }
                &:hover{
                    .list-item-img-overlay{
                        opacity: 0;
                    }
                }
            }
            .list-item-content{
                padding-left: 35px;
                padding-right: 35px;
                @media (min-width: $media_lg) and (max-width: $media_xl){
                    padding-left: 25px;
                    padding-right: 25px;
                }
            }
            .list-item-header {
                margin-top: 35px;
                @media (min-width: $media_lg) and (max-width: $media_xl){
                    margin-top: 25px;
                }
                .list-item-icon{
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-size: 26px 26px;
                    background-position-x: right;
                    background-position-y: top;
                    background-repeat: no-repeat;
                    &:not(:last-child){
                        margin-right: 10px;
                    }
                    &.icon-wifi{
                        width: 29px;
                        background-size: 29px 26px;
                    }
                    &.icon-cooling{
                        width: 23px;
                        background-size: 23px 26px;
                    }
                    &.icon-heating{
                        width: 26px;
                        background-size: 26px 26px;
                    }
                    &.icon-parking{
                        width: 25px;
                        background-size: 25px 26px;
                    }
                    &.icon-tv{
                        width: 28px;
                        background-size: 28px 26px;
                    }
                }
                
            }


            .list-item-body{
                margin-top: 35px;
                @media (min-width: $media_lg) and (max-width: $media_xl){
                    margin-top: 25px;
                }
                .list-item-text{
                    a{
                        color: color('text', 'main');
                        &>* {
                            &:last-child {
                              padding-bottom: 0;
                              margin-bottom: 0;
                            }
                          }
                    }
                    
                }
            }
            .list-item-footer {
                border-top: none;

                background: transparent;
                padding-top: 35px;
                margin-bottom: 35px;
                @media (min-width: $media_lg) and (max-width: $media_xl){
                    margin-top: 25px;
                    margin-bottom: 25px;
                }
                .list-item-btn {
                    font-size: 16px;
                    border: none;
                    i{
                        margin-left: 5px;
                        font-size: 22px;
                        -webkit-text-stroke: 2px color('secondary');
                        position: relative;
                        top: 3px;
                    }   
                    
                        &:hover {
                            text-decoration: none;
                            background: color('secondary');
                            border: none; 

                            i {
                                -webkit-animation: leftToRight 1s ease-out infinite;
                                animation: leftToRight 1s ease-out infinite;
                            }
                        }
                    
                }

            }
        }
    }
}