.banners_wrapper {
    .banner_item {
        position: relative;

        @media(max-width: $media_md) {
            img {
                display: block;
                margin: 0 auto;
                width: 100%;
                margin-bottom: 15px;
            }


        }

        .banner-link {
            &:hover {
                text-decoration: none;
            }
        }

        .banner-content {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: calc(100% - 30px);
            text-align: center;
            padding: 10px 0;
            background: rgba(color('background', 'dark'), 0.3);
            pointer-events: none;

            .banner-title {
                font-weight: 300;
                font-family: font(main);
                font-weight: 600;
                font-size: 16px;
                color: color('white');
                text-transform: uppercase;
            }
        }
    }

}

.group-view-section {
    .container-fluid {
        .banners_wrapper {
            img {
                width: 100%;
            }

            .banner-content {


                .banner-title {
                    font-size: 22px;
                }
            }
        }
    }
}
.custom-close{
    font-size: 50px;
    color: #000000;
    position: absolute;
    right: 10px;
    opacity: 1;
    -webkit-appearance: none;
}
.banner-popup{
    position: absolute;
    width: 900px;
    height: 900px;
    border: 1px solid Black;
    text-align: left;
    position: absolute;
    top: calc(50vh - ( 900px / 2) + 150px);
    left: calc(50vw - ( 900px / 2));
    z-index: 9999;

}
@media (max-width:800px){
    .banner-popup{
        width: 350px;
        height: 350px;
        border: 1px solid Black;
        text-align: left;
        position: absolute;
        top: calc(50vh - ( 350px / 2) + 30px);
        left: calc(50vw - ( 350px / 2));
    }
}