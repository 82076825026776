.share-widget-inner {
  position: absolute;
  top: 4px;
  right: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 40px;

  @media(max-width: $media_sm) {
    // top: unset;
    // bottom: -35px;
    height: 30px;

    .share-icon {
      font-size: 16px;
    }
  }

  .share-icon {
    color: color('text', 'main');
    font-size: 18px;
    display: inline-flex;
    align-items: center;
    height: 100%;

    &#print-icon {
      padding-right: 15px;
      border-right: 1px solid #ccc;
    }

    &#share-icon {
      padding-left: 15px;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .social-icon-popup {
    display: none;
    position: absolute;
    top: -22px;
    right: -3px;
    width: 90px;

    // max-width: 115px;
    //padding: 5px;

    // &:after {
    //     content: "";
    //     width: 0;
    //     height: 0;
    //     border: 7px solid transparent;
    //     border-top-color: darken(color('primary'), 20%);
    //     position: absolute;
    //     bottom: -14px;
    //     left: 7px;
    //     margin-left: -7px;
    //     @include opacity(0.3);
    //     @include rotate(-40deg);
    //     @include transition(all 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55));
    // }

    a {
      color: #fff;
      width: 30px;
      height: 30px;
      line-height: 30px;
      display: inline-block;
      margin-left: -4px;
      background-color: color('primary');
      text-align: center;
      -webkit-font-smoothing: antialiased;
      @include transition(all 0.15s ease-in-out);
      @include translate3d(0, 0, 0);

      &:first-of-type {
        margin-left: 0;
      }

      i {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        @include translate3d(0, 0, 0);

        // &.fa-envelope {
        //   font-size: 1.3rem;
        // }
      }

      &:hover {
        @include opacity(0.65);
        background: lighten(color('primary'), 5%)
      }
    }

    &.active {
      display: block;

      a {
        -webkit-animation-name: socialPopup;
        animation-name: socialPopup;
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;

        //@include loop-list(0.25s, 4);
        &:nth-of-type(1) {
          -webkit-animation-duration: (0.25s*1); // code for each iteration
          animation-duration: (0.25s*1); // code for each iteration
        }

        &:nth-of-type(2) {
          -webkit-animation-duration: (0.25s*2); // code for each iteration
          animation-duration: (0.25s*2); // code for each iteration
        }
      }
    }

    &.on {
      &:after {
        left: 50%;
        @include opacity(1);
        @include rotate(0);
      }
    }
  }
}

@for $i from 1 through 3 {
  .social-icon-popup.active a:nth-of-type(#{$i}) {
    -webkit-animation-duration: (0.25s*$i); // code for each iteration
    animation-duration: (0.25s*$i); // code for each iteration
  }
}


/* KEYFRAMES */
@-webkit-keyframes socialPopup {
  0% {
    opacity: 0;
    transform: rotate(-30deg) translateY(-50px) rotateX(-90deg);
  }

  50% {
    opacity: 1;
    transform: rotate(10deg) translateY(5px) rotateX(2deg);
  }

  100% {
    transform: rotate(0) translateY(0) rotateX(0);
  }
}

@keyframes socialPopup {
  0% {
    opacity: 0;
    transform: rotate(-30deg) translateY(-50px) rotateX(-90deg);
  }

  50% {
    opacity: 1;
    transform: rotate(10deg) translateY(5px) rotateX(2deg);
  }

  100% {
    transform: rotate(0) translateY(0) rotateX(0);
  }
}


.at-expanding-share-button{
  display: none !important;
}