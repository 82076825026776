.home-map-section {
    padding-bottom: 0;
    padding-top: 40px;
}

.map-container {
    height: 500px;
    width: 100%;
    background: lighten(color('background', 'main'), 5%);
}

.find-us-btn {
    background-color: color('white');
    color: #666666 !important;
    position: relative;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 700;
    font-family: font(Montserrat);
    font-size: 14px;
    padding: 7px 15px;
    text-transform: uppercase;
    border: rgba(color('text', 'dark'), 0.3) 1px solid;
    z-index: 2;

    &:hover {
        color: color('white') !important;
        background-color: color('primary');
    }
}

