// .navbar-light {

//     .nav-link {
//         font-size: 1.5rem;
//         color: rgba(0, 0, 0, 1) !important;

//         &.btn-gradient {
//             color: #fff !important;

//             &:hover {
//                 color: #fff !important;
//             }
//         }

//         &:hover {
//             color: color('primary') !important;
//         }
//     }
// }

// @media (max-width: $media_xs) {
//     .navbar-light {
//         .navbar-brand {
//             max-width: 70%;
//             display: inline-block;

//             .navbar-brand-image {
//                 max-width: 100%;
//             }
//         }
//     }
// }

// @media (max-width: $media_lg) {
//     .mobile-nav {
//         flex-basis: 100%;
//         flex-grow: 1;
//         align-items: center;

//         .list-inline-item {
//             a {
//                 display: block;
//                 padding: 0.5rem 2rem;
//                 text-align: center;

//                 &:hover {
//                     text-decoration: none;
//                     color: color('primary');
//                 }
//             }
//         }
//     }
// }


/* HEADER STYLES */
$margin_y: 20px;

.header-container {
    width: 100%;
    max-width: 100vw;

    color: color('nav', 'text', 'main');

    height: auto;
    //overflow-x: hidden;
    background: color('nav', 'background', 'main');


    &.fixed-top {
        backface-visibility: hidden;
        border-bottom: 1px solid color('nav', 'border', 'main');
    }

    &.scrolled {

        .header-nav-main .header-nav .nav-item:hover .dropdown-menu{
            transform: translateY(-33px);
        }
        .header-nav-main {
            height: 100px;

            .company-brand {
                img {
                    transform: scale(0.87);
                }
            }

            // .header-nav-btn--collapse {
            //     height: 60px;
            // }
        }
    }


    // firefox scrollbar
    // overflow-y: scroll;
    // scrollbar-color: rgba(color('primary'), 0.7) transparent;
    // scrollbar-width: thin;

    // //internet explorer scrollbar
    // scrollbar-face-color: color('primary');
    // scrollbar-arrow-color: color('primary');
    // scrollbar-track-color: transparent;
    // scrollbar-shadow-color: rgb(0, 0, 0);
    // scrollbar-highlight-color: rgb(0, 0, 0);
    // scrollbar-3dlight-color: color('primary');
    // scrollbar-darkshadow-Color: transparent;

    // // webkit browsers (chrome...) scrollbar
    // &::-webkit-scrollbar-track {
    //     box-shadow: inset 0 0 6px rgba(146, 192, 31, 0.3);
    //     background-color: transparent;
    // }

    // &::-webkit-scrollbar {
    //     width: 5px;
    //     background-color: transparent;
    // }

    // &::-webkit-scrollbar-thumb {
    //     background-color: rgba(color('primary'), 0.7);
    // }

    // &.scrolled {}

    .header-nav-top {
        background: color('nav', 'background', 'accent');
        border-bottom: 1px solid color('nav', 'border', 'main');


        max-height: 40px;

        .company-info-header {
            @media(max-width: $media_sm) {
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }

    .header-nav-main {
        max-height: 140px;
        height: 140px;
        @include transition(height 0.3s linear);

        /* Header logo */
        .company-brand {
            text-align: left;
            // height: 100%;

            a {
                // display: flex;
                // height: 100%;
                // align-items: center;
                @include transition(height .3 linear);

                img {
                    @include transition(transform .3 linear);
                    @media(max-width: $media_sm){
                        max-width: 120px;
                    }
                }
            }


        }

        .reservation-container {
            display: inline-block;
            position: relative;

            .reservation-text {
                display: none;
            }
            
            @media(max-width: 1200px){
                .reservation-text{
                    display: inline-block;
                    margin-left: 5px;
                    font-size: 14px;
                }
            }
            @media(max-width: 305px){
                .reservation-text{
                    display: none;
                    
                }
            }
            @media(min-width: 1200px) and (max-width: 1600px) {
                .reservation-btn {
                    font-size: 17px;
                }
            }

            @media(min-width: 1400px) {
                .reservation-btn {
                    i {
                        display: none;

                    }

                    .reservation-text {
                        display: inline;
                    }
                }
            }
        }

        .header-nav {
            position: relative;
            bottom: 0;
            font-size: 19px;
            height: 100%;
            // line-height: 80px;
            font-family: font(sub);
            font-weight: 400;
            margin-bottom: 0;

            .nav-item {
                height: 100%;
                display: inline-flex;
                align-items: center;
                margin-right: 0;

                &:hover,
                &.active {
                    // border-bottom: 3px solid color('primary');
                    color: color('primary');
                }

                position: relative;

                &:hover {
                    .dropdown-menu {
                        display: block;
                        position: absolute;
                        margin: 0;
                        left: -1px;
                        transform: translateY(-54px);
                    }
                }

                .dropdown-menu {
                    z-index: 1030;
                    line-height: 1.5;
                    background: lighten(color('nav', 'background', 'accent'), 5%);
                    color: color('nav', 'text', 'main');
                    border-color: color('nav', 'border', 'main');
                    min-width: 100%;
                    @include border-radius(0);

                    li {
                        &:not(:last-of-type) {
                            border-bottom: 1px solid color('border', 'main');

                        }

                        margin-left: 10px;
                        margin-right: 10px;
                    }

                    .dropdown-item {
                        padding: 0.5rem 1.5rem;

                        &:active,
                        &:focus,
                        &:visited {
                            background: transparent;
                            color: color('white');
                        }

                        &.active,
                        &:hover {
                            background: color('primary');
                            color: color('white');
                        }

                        font-size: 17px;
                        font-family: font(main);
                        text-transform: uppercase;


                    }
                }

                .nav-link {
                    padding: 0 15px;

                    @media(min-width: 1200px) and (max-width: 1600px) {
                        padding: 0 10px;
                        font-size: 17px;
                    }

                    @media(min-width: 1200px) and (max-width: 1300px) {
                        padding: 0 5px;
                    }
                }
            }
        }
    }

}

/* Header social icons */
.social-container {
    position: relative !important;
    padding: 0;

    .social-popup,
    .social-popup-trigger {
        display: none;
    }

    .social-link {
        color: color('nav', 'text', 'accent');
        text-align: center;
        padding-left: 0;
        border: none;
        line-height: 40px;
        display: inline-block;

        &.icon-only {
            font-size: 15px;
            padding-right: 10px;

            @media(max-width: 360px) {
                padding-right: 0px;
            }

            i {
                font-size: 15px;
            }

            i.fa-facebook {
                font-size: 13px;
            }

            i.fa-google-plus {
                font-size: 15px;
            }

            i.fa-linkedin-in {
                font-size: 14px;
            }
        }

        &:not(:first-of-type) {
            padding-left: 10px;

            @media(max-width: 360px) {
                padding-left: 0px;
            }
        }

        &:not(:last-of-type) {
            border-right: 1px solid color('nav', 'border', 'main') !important;
        }

        &:first-of-type {
            padding-left: 0px;
            padding-right: 10px;

            @media(max-width: 360px) {
                padding-right: 0px;
            }
        }


        i {
            vertical-align: middle;

            color: color('primary');
            line-height: 40px;
        }

    }
}

/* HEADER CONTACT */
.contact-info {
    text-align: right;
    display: inline-block;

    a {
        line-height: 40px;
        font-family: font(sub);
        display: inline-block;
        border-left: 1px solid color('nav', 'border', 'main');
        color: color('nav', 'text', 'accent');
        padding: 0 10px;
        font-size: 16px;
        padding-left: 15px;

        &:hover {
            text-decoration: none;
            color: color('primary');
        }
    }

    i {
        color: color('primary');
        line-height: 40px;
        padding-right: 5px;

        &.fa-phone,
        &.fa-phone-alt {
            font-size: 14px;

            @media(max-width: $media_sm) {
                font-size: 12px;
            }
        }

        &.fa-skype {
            font-size: 14px;
        }

        &.fa-envelope {
            font-size: 12px;
        }
    }

    .nav-contact-text {
        display: none;

        @media (min-width: 1600px) {

            display: inline;
        }
    }

    &.text-muted {
        display: inline;
        margin-left: 1px;


    }

    label {
        display: inline;
        margin: 0;
    }
}

.lang-wrap {
    // margin-right: 5px;
    position: relative;

    .dropdown-menu {
        top: 40px !important;
        left: initial !important;
        right: 0px;
        min-width: 100%;
        font-size: 16px;
        color: color('nav', 'text', 'accent');
        background: lighten(color('nav', 'background', 'accent'), 5%);
        border-color: color('nav', 'border', 'main');
        transform: none !important;

        .dropdown-item {
            &:visited {
                color: color('nav', 'text', 'accent');
            }

            &:focus {
                background: transparent;
                color: color('white');
            }

            &:hover {
                background: color('primary');
                color: color('white');
            }

            &:active,
            .active {
                background: color('primary');
                color: color('white');
            }
        }
    }

    a {
        line-height: 40px;
        text-decoration: none;
        text-transform: capitalize;
        font-size: 16px;
        font-family: font(sub);
        font-weight: 400;
        color: color('nav', 'text', 'accent');

        &.dropdown-toggle::after {
            border: 0 !important;
            content: "\f107" !important;

            vertical-align: 0 !important;
            margin-left: 5px !important;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 14px;
            -webkit-text-stroke: 1px color('nav', 'background', 'accent');

        }

        .lang-label {
            @media(max-width: $media_md) {
                display: none;
            }
        }

        img {
            width: 21px;
            margin-right: 10px;

            @media(max-width: $media_md) {
                margin-right: 0;
            }
        }

        @include transition(all .3 linear);

        &.active {
            color: color('primary');
        }

        &:hover {
            opacity: 0.7;
        }

        // &:not(:first-of-type) {
        //     padding-left: 10px;

        // }

        // &:not(:last-of-type) {
        //     border-right: 1px solid color(border-light);
        //     padding-right: 10px;
        //     padding-left: 25px;
        // }
    }
}

/* BUTTON HAMBURGER */
.btn-menu {
    // margin-left: auto;
    // display: inline-block;
    // padding: $margin_y+10;
    color: color('white');
    display: inline-flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
    padding: 0 12px;
    height: 44px;
    position: relative;
    top: 4px;

    &:hover {
        text-decoration: none;
    }

    .menu-text {
        text-transform: uppercase;
        font-size: 16px;
        font-family: font(sub);
        font-weight: 700;
        // line-height: 32px;
        // position: relative;
        // top: -5px;
        // left: 10px;
        display: none;

        @media(min-width: $media_sm) {
            display: inline-block;
            margin-left: 8px;
        }
    }

    .menu-icon {

        // margin-left: 10px;
        // height: 100%;
        span {
            display: block;
            background-color: color('white');
            width: 20px;
            height: 2px;
            margin-bottom: 4px;
        }
    }

}


/* MEDIA QUERIES */
@media (max-width: 1700px) {
    .contact-info {
        span {
            line-height: 40px;
            padding: 0 8px;

            i {
                margin-right: 5px;
            }
        }
    }
}

@media (max-width: 1600px) {
    .header-container {
        .social-container {
            .social-link {
                width: 40px;
                line-height: 40px;
            }
        }
    }

    .contact-info {
        span {
            line-height: 40px;
            padding: 0 8px;

            i {
                margin-right: 5px;
            }
        }
    }
}

@media (max-width: 1300px) {
    .contact-info {

        //text-align: left;
        span {
            label {
                display: none;
            }
        }
    }
}

@media(max-width: 1040px) {
    .contact-info {
        span {
            padding: 0 4px;
            border: 0 !important;
            font-size: 1.1rem;
        }
    }
}

@media (max-width: $media_xl) {
    .contact-info {
        .text-muted {
            display: none;
        }
    }
}

@media (max-width: $media_lg) {


    .btn-menu {
        // margin-top: 5px;

        .menu-text {
            display: none !important;
        }
    }

    .mobile-lang {
        font-size: 1rem;
        height: 38px;
    }

    .mobile-book {
        position: relative;
        top: 45px;
        font-size: 1.2rem;
        background-color: color('primary');

        i {
            color: color('white');
        }
    }
}


@media(max-width: 320px) {
    .header-container {
        .social-container {
            .social-link {
                width: 30px;

            }
        }
        .contact-info a{
            padding: 0 5px;
            padding-left: 10px;
        }
    }
}