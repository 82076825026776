.section {
    &.detail-section {

        .detail-title{
            text-align:left;
        }
        .detail-icons{
            margin-bottom: 20px;
        }
        .icon-wrapper{
            display: inline-flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 15px;
            &:not(:last-child){
                margin-right: 25px;
            }
        }
        .icon-desc{
            display: block;
            font-family: font(Montserrat);
            font-size: 8px;
            text-transform: uppercase;
            margin-top: 10px;
        }
        .detail-icon{
            display: inline-block;
            width: 40px;
            height: 40px;
            background-size: 40px 40px;
            background-position-x: left;
            background-position-y: top;
            background-repeat: no-repeat;
            opacity: 0.8;
            
            &.icon-wifi{
                width: 45px;
                background-size: 45px 40px;
            }
            &.icon-cooling{
                width: 35px;
                background-size: 35px 40px;
            }
            &.icon-heating{
                width: 40px;
                background-size: 40px 40px;
            }
            &.icon-parking{
                width: 39px;
                background-size: 39px 40px;
            }
            &.icon-tv{
                width: 43px;
                background-size: 43px 40px;
            }
        }

        .detail-text {
            &.column-2 {
                -webkit-columns: 2;
                -moz-columns: 2;
                columns: 2;

                @media (max-width: $media_md) {
                    -webkit-columns: 1;
                    -moz-columns: 1;
                    columns: 1;
                    margin: 0 auto;
                }
            }
        }
        .reservation-btn{
            margin-top: 35px;
        }
    }
}