/* FONT IMPORTS */
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Khand:300,400,700&display=swap');
//@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,800');
//@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,700,800');




/* FONTS */
$fonts: (Montserrat: ('Montserrat', sans-serif),
    OpenSans: ('Open Sans', sans-serif),
    main: ('Khand', sans-serif),
    sub: ('Khand', sans-serif),
);

@function font($key) {
    @if map-has-key($fonts, $key) {
        @return map-get($fonts, $key);
    }

    @warn "Unknown `#{$key}` in $fonts.";
    @return null;
}