/* GENERAL MIXINS */
@mixin text-shadow ($string: 0 1px 3px rgba(0, 0, 0, 0.25)) {
    text-shadow: $string;
}

@mixin box-shadow ($string) {
    -webkit-box-shadow: $string;
    -moz-box-shadow: $string;
    box-shadow: $string;
}

@mixin drop-shadow ($x: 0, $y: 1px, $blur: 2px, $spread: 0, $alpha: 0.25) {
    -webkit-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
    -moz-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
    box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
}

@mixin inner-shadow ($x: 0, $y: 1px, $blur: 2px, $spread: 0, $alpha: 0.25) {
    -webkit-box-shadow: inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
    -moz-box-shadow: inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
    box-shadow: inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
}

@mixin box-sizing ($type: border-box) {
    -webkit-box-sizing: $type;
    -moz-box-sizing: $type;
    box-sizing: $type;
}

@mixin border-radius ($radius: 5px) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;

    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

@mixin border-radiuses ($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
    -webkit-border-top-right-radius: $topright;
    -webkit-border-bottom-right-radius: $bottomright;
    -webkit-border-bottom-left-radius: $bottomleft;
    -webkit-border-top-left-radius: $topleft;

    -moz-border-radius-topright: $topright;
    -moz-border-radius-bottomright: $bottomright;
    -moz-border-radius-bottomleft: $bottomleft;
    -moz-border-radius-topleft: $topleft;

    border-top-right-radius: $topright;
    border-bottom-right-radius: $bottomright;
    border-bottom-left-radius: $bottomleft;
    border-top-left-radius: $topleft;

    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

@mixin opacity ($opacity: 0.5) {
    -webkit-opacity: $opacity;
    -moz-opacity: $opacity;
    opacity: $opacity;
}

@mixin gradient ($startColor: #eee, $endColor: white) {
    background-color: $startColor;
    background: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
    background: -webkit-linear-gradient(top, $startColor, $endColor);
    background: -moz-linear-gradient(top, $startColor, $endColor);
    background: -ms-linear-gradient(top, $startColor, $endColor);
    background: -o-linear-gradient(top, $startColor, $endColor);
}

@mixin horizontal-gradient ($startColor: #eee, $endColor: white) {
    background-color: $startColor;
    background-image: -webkit-gradient(linear, left top, right top, from($startColor), to($endColor));
    background-image: -webkit-linear-gradient(left, $startColor, $endColor);
    background-image: -moz-linear-gradient(left, $startColor, $endColor);
    background-image: -ms-linear-gradient(left, $startColor, $endColor);
    background-image: -o-linear-gradient(left, $startColor, $endColor);
}

@mixin animation ($name, $duration: 300ms, $delay: 0, $ease: ease) {
    -webkit-animation: $name $duration $delay $ease;
    animation: $name $duration $delay $ease;
}

@mixin transition ($transition) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

@mixin transform($string) {
    -webkit-transform: $string;
    -moz-transform: $string;
    -ms-transform: $string;
    -o-transform: $string;
}

@mixin scale ($factor) {
    -webkit-transform: scale($factor);
    -moz-transform: scale($factor);
    -ms-transform: scale($factor);
    -o-transform: scale($factor);
}

@mixin rotate ($deg) {
    -webkit-transform: rotate($deg);
    -moz-transform: rotate($deg);
    -ms-transform: rotate($deg);
    -o-transform: rotate($deg);
}

@mixin skew ($deg, $deg2) {
    -webkit-transform: skew($deg, $deg2);
    -moz-transform: skew($deg, $deg2);
    -ms-transform: skew($deg, $deg2);
    -o-transform: skew($deg, $deg2);
}

@mixin translate ($x, $y:0) {
    -webkit-transform: translate($x, $y);
    -moz-transform: translate($x, $y);
    -ms-transform: translate($x, $y);
    -o-transform: translate($x, $y);
}

@mixin translate3d ($x, $y: 0, $z: 0) {
    -webkit-transform: translate3d($x, $y, $z);
    -moz-transform: translate3d($x, $y, $z);
    -ms-transform: translate3d($x, $y, $z);
    -o-transform: translate3d($x, $y, $z);
}

@mixin perspective ($value: 1000) {
    -webkit-perspective: $value;
    -moz-perspective: $value;
    -ms-perspective: $value;
    perspective: $value;
}

@mixin transform-origin ($x:center, $y:center) {
    -webkit-transform-origin: $x $y;
    -moz-transform-origin: $x $y;
    -ms-transform-origin: $x $y;
    -o-transform-origin: $x $y;
}

/* FLEXBOX UTILITIES */
@mixin flex-width($args) {
    -webkit-box-flex: 0;
    -ms-flex: $args;
    flex: $args;
}

@mixin d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

@mixin justify-content-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    justify-content: center !important;
}

@mixin justify-content-around {
    -webkit-box-align: space-around !important;
    -ms-flex-align: space-around !important;
    justify-content: space-around !important;
}

@mixin justify-content-between {
    -webkit-box-align: space-between !important;
    -ms-flex-align: space-between !important;
    justify-content: space-between !important;
}

@mixin align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
}

@mixin flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

@mixin flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
}

@mixin gradient-two-clr-linear($clr-start, $clr-end) {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#3fb4d7+0,8fcb86+100 */
    background: $clr-start;
    /* Old browsers */
    background: -moz-linear-gradient(left, $clr-start 0%, $clr-end 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, $clr-start 0%, $clr-end 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $clr-start 0%, $clr-end 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$clr-start', endColorstr='$clr-end', GradientType=1);
    /* IE6-9 */
}

@mixin gradient-two-clr-diagonal--45deg ($clr1, $clr2) {
    background: $clr1;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, $clr1 0%, $clr2 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, $clr1 0%, $clr2 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, $clr1 0%, $clr2 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$clr1', endColorstr='$clr2', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
}

@mixin placeholder($font-size, $font-weight, $font-style, $color) {
    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: $font-size;
        font-weight: $font-weight;
        font-style: $font-style;
        color: $color;
    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        font-size: $font-size;
        font-weight: $font-weight;
        font-style: $font-style;
        color: $color;
    }

    &:-ms-input-placeholder {
        /* IE 10+ */
        font-size: $font-size;
        font-weight: $font-weight;
        font-style: $font-style;
        color: $color;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        font-size: $font-size;
        font-weight: $font-weight;
        font-style: $font-style;
        color: $color;
    }

    &::placer {
        font-size: $font-size;
        font-weight: $font-weight;
        color: $color;
    }
}


@mixin page-text() {
    h1 {
        font-family: font(main);
        font-weight: 300;
        font-size: 44px;
        margin-bottom: 20px;

        strong {
            font-weight: 700;
        }
    }

    h2 {
        font-family: font(main);
        font-weight: 300;
        font-size: 32px;
        margin-bottom: 1.5rem;

        strong {
            font-weight: 700;
        }
    }

    h3 {
        font-family: font(sub);
        font-weight: 300;
        font-size: 26px;
        margin-bottom: 1.5rem;

        strong {
            font-weight: 700;
        }
    }

    p {
        font-family: font(sub);
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        padding-bottom: 25px;

    }

    ul {
        list-style: square;
        // margin-top: 1rem;
        margin-bottom: 25px;
        margin-left: 0;
        margin-right: 0;
        padding-left: 25px;

        li {
            font-family: font(sub);
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
        }
    }

    ol {
        list-style: disc;
        list-style-type: decimal;
        // margin-top: 1rem;
        margin-bottom: 25px;
        margin-left: 0;
        margin-right: 0;
        padding-left: 40px;

        li {
            font-family: font(sub);
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
        }
    }

    strong {
        font-weight: 700;
    }

    a {
        color: color('primary');
        @include transition(all .3s linear);

        img {
            @include transition(all .3s linear);
        }

        &:hover {
            color: darken(color('primary'), 5%) img {
                opacity: 0.7;
            }
        }
    }

    img {
        max-width: 100%;
        height: auto;
        margin-bottom: 1rem;
    }
}