.section {
  padding-top: 80px;
  padding-bottom: 80px;
  
    .header-share-container {
      position: relative;
    }
  

  hr.section-separator {
    border: none;
    height: 3px;
    background: color('border', 'dark');
    color: color('border', 'dark');
    width: 100px;
    position: relative;
    margin-top: 35px;
    margin-bottom: 35px;

    &:before {
      content: '';
      height: 1px;
      color: color('border', 'dark');
      background: color('border', 'dark');
      width: 50px;
      display: block;
      position: absolute;
      left: -50px;
      top: 1px;
    }

    &:after {
      content: '';
      height: 1px;
      color: color('border', 'dark');
      background: rgba(255,255,255,0.2);
      width: 50px;
      display: block;
      position: absolute;
      right: -50px;
      top: 1px;
    }

  }
  .section-separator--left{
    @extend .section-separator;
    margin-left: 0;
    margin-right: 0;

    &:before{
      display: none !important;
    }
  }
  .section-title {
    font-family: font(main);
    font-size: 44px;
    font-weight: 300;
    color: color('text', 'accent');
    text-align: center;
    text-transform: uppercase;
    line-height: 1;

    &.align-left-title {
      text-align: left;
    }

    &h1 {
      font-size: 44px;
    }
  }

  .section-subtitle {
    font-family: font(main);
    font-weight: 300;
    font-size: 32px;
    color: color('text', 'accent');
    text-align: center;
    // font-style: italic;
    margin-bottom: 35px;
    line-height: 1;
  }

  .section-text {
    font-family: font(sub);
    font-weight: 400;
    font-size: 18px;
    color: color('text', 'main');

    &>* {
      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    @include page-text();

  }

  .section-btn {
    margin: 0 auto;
    margin-top: 35px;
    color: color('white') !important;

    i {
      margin-right: 10px;
      font-size: 16px;
    }
  }
}