.popup-info {
    padding: 0;

    .popup-trigger {
        padding: 0;
        border-left: none;
    }
}

#popup-content {
    display: none;
}

.popup-overlay-0 {
    .simplePopupClose {
        color: #FFF;
        font-size: 26px;
        position: absolute;
        right: -30px;
        top: -30px;

        &:hover {
            color: #83b817;
            cursor: pointer;
        }
    }

    .section-title {
        font-size: 36px;
        font-weight: 300;
        color: #ffffff;
        text-align: center;
        text-transform: uppercase;
        line-height: 1;
    }

    hr {
        border: none;
        height: 3px;
        background: #83b817;
        width: 100px;
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    p {
        font-size: 16px;
        text-align: center;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 15px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}