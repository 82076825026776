/* FORM STYLES */
.dm-form-wrapper {
    margin-bottom: 35px;

    .form-group {
        label {
            font-size: 16px;
        }
    }

    .form-control {
        font-size: 16px;
        padding: 5px 15px;
        height: 44px;
        background: lighten(color('background', 'main'), 5%);
        border-color: color('border', 'main');
        color: color('text', 'main');

        &:focus {
            border-color: transparent;
        }
    }

    .text_widget {
        width: 100%;
    }

    textarea {
        height: 150px !important;
    }

    .checkbox {
        font-size: 16px;
        margin: 20px 0;

        &.custom-checkbox{
            .custom-control-input{
                left: 0;
            }
        }

        &.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
            background-image: none;
            content: "\f00c";
            font-family: 'Font Awesome 5 Free';
            font-size: 11px;
            font-weight: 600;
            color: color('primary');
        }

        label {
            margin: 0;
            font-size: 14px;
            line-height: 23px;

            span.required {
                color: color('primary');
            }

            a {
                color: color('primary');
            }

            &.custom-control-label {
                &:before {
                    @include border-radius(0px);
                    width: 17px;
                    height: 17px;
                    background-color: lighten(color('background', 'main'), 5%);
                    border-color: color('border', 'main');
                }

                &:after {
                    width: 17px;
                    height: 17px;
                    left: -18.5px;
                    top: 0px;
                }
            }
        }

        .custom-control-input:checked~.custom-control-label::before {
            background-color: lighten(color('background', 'main'), 5%);
            border-color: color('border', 'main');
        }

        input[type="checkbox"] {
            width: 17px;
            height: 17px;
            // float: left;
            margin-right: 10px;
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }


    select {
        height: 44px !important;
    }

    .error {
        border-color: red !important;
    }

    &.col-2-form {
        margin-bottom: 0;

        >div {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .form-group {
                width: calc(50% - 7px);

                &.standalone-row {
                    width: 100%;

                    .form-control {
                        width: calc(50% - 7px);
                    }
                }

                &.full-row {
                    width: 100%;
                }
            }
        }
    }
}

.form-title {
    font-size: 26px;
    font-family: font(main);
    color: color('text', 'accent');
    margin-bottom: 35px;
    font-weight: 300;
    text-transform: uppercase;

    .fa-sign-in {
        color: color('secondary');
    }

    .fa-key {
        color: color('primary');
    }

    +p {
        font-family: font(sub);
        font-size: 18px;
        margin-bottom: 35px;

    }
}

@media(max-width: $media_xs) {
    .dm-form-wrapper {
        &.col-2-form {
            >div {
                display: block;

                .form-group {
                    width: 100%;

                    &.standalone-row {
                        width: 100%;

                        .form-control {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}