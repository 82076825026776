.phobs-reservation-section{
    .iframe-holder {
            // margin-top: 30px;
        position: relative;
        // padding-bottom: 100%;
            // padding-top: 35px;
        height: 1000px;
        overflow: hidden;
    
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &.desktop {
                display: none;
            }
            &.mobile{
                 display: block;
            }
            @media (min-width: 967px) {
                &.desktop {
                    display: block;
                }
                &.mobile {
                    display: none;
                }
            }

        }
    }
    
    // .phobs{
    //     width: 100%;
    //     height: 1000px;
    // }
    
}